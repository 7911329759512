const { DEFAULT_LANGUAGE } = require("./languages")

const ROUTES = (lang = DEFAULT_LANGUAGE) => {
  lang = lang !== DEFAULT_LANGUAGE ? `/${lang}` : ""
  const lmodDomain = process.env.GATSBY_LMOD_DOMAIN
  const myDomain = process.env.GATSBY_LMOD_MY_DOMAIN

  // There are 2 vimeo sites: prod and test, so not using lmodDomain here
  const watchDomain = process.env.GATSBY_LMOD_WATCH_DOMAIN

  return {
    PAYMENT_HISTORY: `${lang}/payment-history`,
    SIGN_IN: `${lang}/sign-in`,
    SIGN_OUT: `${lang}/sign-out`,
    FORGOT_PASSWORD: `${lang}/forgot-password`,
    RESET_PASSWORD: `${lang}/reset-password`,
    SALES_LANDING: `${lang}/`,
    CHANGE_SUBSCRIPTION: `${lang}/change-subscription`,
    EDIT_PERSONAL_INFORMATION: `${lang}/edit-personal-information`,
    UPDATE_PAYMENT_DETAILS: `${lang}/update-payment-details`,
    CREATE_ACCOUNT: `${lang}/create-account`,
    GETTING_STARTED: `${lang}/getting-started`,
    FAQ: `${lang}/faq`,
    NOT_FOUND: `${lang}/404`,
    OFFERS: `${lang}/offers`,
    EMAIL_NEWSLETTER: `${lang}/confirm`,
    CUSTOMER_ACCOUNT: `${lang}/customer/account`,
    CREATE_ACCOUNT_AFFILIATE: `${lang}/create-account-affiliate`,
    CHECK_EMAIL: `${lang}/check-email`,
    COOKIE_POLICY: `${lang}/cookie-policy`,
    TERMS_OF_USE: `${lang}/terms-of-use`,
    PRIVACY_POLICY: `${lang}/privacy-policy`,
    MEMBERSHIP: `${lang}/membership`,
    MAINTENANCE: `${lang}/maintenance`,
    MEMBER_DISCOUNT: `${lang}/member-discount`,
    CUSTOMER_WATCH: `/customer/watch`,
    CUSTOMER_ACTIVATE: `/customer/activate`,
    NZ_OFFERS: `${lang}/nz-offers`,
    PROMOTION: `${lang}/promotion`,
    LOGIN_SSO: `https://www.${lmodDomain}/en_us/customer/account/loginsso`,
    LMOD_CUSTOMER_ACCOUNT: `https://www.${lmodDomain}/en_us/customer/account`,
    LMOD_CUSTOMER_WATCH: `https://watch.${watchDomain}/browse`,
    LMOD_CUSTOMER_ACTIVATE: `https://watch.${lmodDomain}/activate`,
    LMOD_VIMEO_SIGN_IN: `https://watch.${lmodDomain}/login`,
    CHALLENGES: `${lang}/challenges`,
    UNSUBSCRIBE_CHALLENGES: `${lang}/challenges/unsubscribe`,

    // Public redirection pages
    LMOD_CART_JOURNEY: `https://www.${lmodDomain}/en_us/subscriptions/signup`,

    // Platforms sign out URLs
    VIMEO_SIGN_OUT: `https://watch.${lmodDomain}/logout`,
    MAGENTO_SIGN_OUT: `https://www.${lmodDomain}/en_us/customer/account/logout/`,

    // Plux platform disable reCaptcha
    DISABLE_RECAPTCHA: `/plux/index/disablecaptcha`,
    RESELLER_REDEEM: `${lang}/reseller-redeem/`,
    RESELLER_SIGN_IN: `${lang}/reseller-sign-in/`,
    RESELLER_SIGN_UP: `${lang}/reseller-signup`,
    V2_RESELLER_SIGN_UP: `${lang}/v2/reseller-signup`,
    RESELLER_ADDITIONAL_INFO: `${lang}/reseller-additional-info`,

    // Federated page
    FEDERATED_VERIFICATION: `${lang}/federated-verification`,
    FEDERATED_SIGNUP: `${lang}/federated-signup`,

    // Partner promotion
    PARTNER_PROMOTION: `${lang}/partner-promotion`,

    // Cookie declaration page
    COOKIE_DECLARATION: `${lang}/cookie-declaration/`,

    LMOD_EXPLORE: `https://my.${myDomain}/explore`,
    LMOD_SURVEY: `https://my.${myDomain}/survey/start`,

    LMPLUS_PLANS: `https://my.${myDomain}/plans`,
  }
}

module.exports = {
  ROUTES,
}
