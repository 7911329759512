// @flow
import React from "react"
import isEqual from "lodash/isEqual"
import Navigations from "../Navigations"
import Button from "../Button"

import { navigate } from "gatsby"
import { ROUTES } from "../../constants/routes"

import { isEmpty } from "../../utils/utilities"

import type { UserType } from "../../types/UserType"

type Props = {|
  navs: Array<Object>,
  lang: string,
  user: UserType,
|}

const Footer = ({ navs, lang, user }: Props) => {
  const handleNavigate = () => {
    navigate(ROUTES(lang).SIGN_IN)
  }

  return (
    <footer className="flex px-22 bg-gray-950 py-15" data-cy="footer">
      <Navigations
        navs={navs}
        classNames={{
          wrapper: "flex flex-wrap md:justify-start items-center",
          item: "mr-20 text-gray-80 font-base-light text-xs footer-nav",
        }}
        lastChild={
          isEmpty(user) && (
            <Button
              className="mr-10 sm:mr-20 text-gray-80 font-base-light text-xs"
              handleOnClick={handleNavigate}
            >
              Sign In
            </Button>
          )
        }
      />
    </footer>
  )
}

export default React.memo(Footer, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.navs, nextProps.navs) &&
    isEqual(prevProps.user, nextProps.user) &&
    prevProps.lang === nextProps.lang
  )
})
